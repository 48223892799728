import React from 'react'

import './styles.scss'

type Props = {
    pretitle?: string
    message?: string
    subtitle?: string
}

export default function JuggleLoading({
    pretitle, message, subtitle
}: Props) {
  return (
    <div id='JuggleLoading'>
      <div className='preload'>
        <div className='ball'></div>
        <div className='ball'></div>
        <div className='ball'></div>
      </div>
      <span className="texts">
        {message && <p><small>{message}</small></p>}
        {pretitle && <p><strong>{pretitle}...</strong></p>}
        {subtitle && <p>{subtitle}</p>}
      </span>
    </div>
  )
}

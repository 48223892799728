export function updateRoutes({ learningRoutes }: any){
    return (dispatch: any) => {
        dispatch({ 
            type: 'SET_LEARNING_ROUTES',
            payload: {
                learningRoutes
            }
        })
    }
}

export function attemptLogin({ token, userdata }: any){
    // const decodedToken: any = jwt_decode(token)
    const isLogged = true
    console.log(token, userdata)
    userdata.fullName = `${userdata.firstName} ${userdata.lastName}`

    return (dispatch: any) => {
            dispatch({ 
                type: 'LOGIN_USER',
                payload: {
                    token,
                    userInfo: userdata,
                    isLogged
                }
            })
            return isLogged
    }
}

export function logout(payload: any){
    return { type: 'LOGOUT', payload }
}
